import React from "react"
import { useStaticQuery, graphql } from "gatsby"

const Post = () => {
  const data = useStaticQuery(graphql`
    query MyQuery {
      allWpPost {
        nodes {
          content
          uri
          title
          id
        }
      }
    }
  `)

  return (
    <div>
      {data &&
        data.allWpPost.nodes.map((post) => {
          return (
            <div key={post.id}>
              <h1>{post.title}</h1>
              <div dangerouslySetInnerHTML={{ __html: post.content }} />
            </div>
          )
        })}
    </div>
  )
}

export default Post
